$theme-colors: (
    "info": #A0D2DB,
    "danger": #CC3314,
    "warning": #ffc025,
    "success":#14B850,
    "primary":#515bb8,
    "dark": #110B11,
    "secondary": #1B2845,
    "light-secondary": #393e46,
    "light-primary":#F1EBFF,
    "light-back":#ebf1ff,
    "light-deactive":#727c8b
);

$navbar-padding-x:1rem;
$navbar-padding-y: 1rem;

input[type=text], input[type=password] {
    height:2.75rem;
    border-radius: 0.25rem;
}

@import '~bootstrap/scss/bootstrap.scss';