.xcd-input-label {
    font-size: 0.8rem;
    font-weight: 900;
  }
  .xcd-input-text {
    font-size: 0.9rem;
  }
  .xcd-input-text:focus {
    border-color:#c76c30;
    box-shadow: none;
  }
  